import React from 'react';
// import { Link } from 'gatsby';
const altran = require("../images/partenaires-logo-altran.svg")
const agnesb = require("../images/partenaires-logo-anges-b.svg")
const bnp = require("../images/partenaires-logo-bnp.svg")
const fpa = require("../images/partenaires-logo-fpa.svg")
const monacoexploration = require("../images/partenaires-logo-monaco-explorations.svg")
const paulsen = require("../images/partenaires-logo-paulsen.svg")

/**
 * BNP : https://aboutglobalmarkets.bnpparibas.com/sustainability/ 
 * Explorations de Monaco : https://www.monacoexplorations.org/ 
 * Fondation Prince Albert 2 : https://www.fpa2.org/ 
 * Editions Paulsen : https://www.editionspaulsen.com/ 
 * Agnès b. : https://www.agnesb.eu/ 
 * Altran : https://www.altran.com/fr/fr/
 */
const Partenaires = () => {
  const logos = [
    {
      file: fpa,
      height:24,
      url: 'https://www.fpa2.org/'
    }, {
      file: monacoexploration,
      height:30,
      url: 'https://www.monacoexplorations.org/'
    },  {
      file: bnp,
      height:20,
      url: 'https://aboutglobalmarkets.bnpparibas.com/sustainability/'
    },  {
      file: paulsen,
      height:28,
      url: 'https://www.editionspaulsen.com/'
    }, {
      file: altran,
      height:13,
      url: 'https://www.altran.com/fr/fr/'
    }, {
      file: agnesb,
      height:25,
      url: 'https://www.agnesb.eu/'
    }]
  console.log(monacoexploration)
  return (
    <ul className="partenaires x xac">
      {logos.map((li, i) => (
        <li key={i}>
          <a href={li.url} target="_blank" rel="noopener noreferrer">
            <img src={li.file} alt="logo partenaires" height={li.height} width="" />
          </a>
        </li>
      ))}
    </ul>
  );
};

export default Partenaires;
// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-api-jsx": () => import("./../src/pages/api.jsx" /* webpackChunkName: "component---src-pages-api-jsx" */),
  "component---src-templates-default-jsx": () => import("./../src/templates/default.jsx" /* webpackChunkName: "component---src-templates-default-jsx" */),
  "component---src-templates-home-jsx": () => import("./../src/templates/home.jsx" /* webpackChunkName: "component---src-templates-home-jsx" */),
  "component---src-templates-modulaire-jsx": () => import("./../src/templates/modulaire.jsx" /* webpackChunkName: "component---src-templates-modulaire-jsx" */),
  "component---src-templates-zone-jsx": () => import("./../src/templates/zone.jsx" /* webpackChunkName: "component---src-templates-zone-jsx" */),
  "component---src-templates-zones-jsx": () => import("./../src/templates/zones.jsx" /* webpackChunkName: "component---src-templates-zones-jsx" */)
}


import React, {useEffect, useState, useContext, createContext, useRef} from 'react'
import {useStaticQuery, graphql} from 'gatsby'
import Header from './Header'
import Footer from './Footer'
// import ZonesMap from '../components/ZonesMap'
import ZonesMap from './ZonesMap'
import Video from './ui/VideoWrapper'
import {LocaleContext} from './ui/LocaleWrapper'

const WrapperContext = createContext()

const query = graphql `
  query {
    sanitySettings {
      title
      # video,
      videoMp4FR{asset { url }},
      videoMp4EN{asset { url }},
      videoMp4IT{asset { url }},
      codesBanque
    }
    zonesFR: allSanityZone(filter: {locale: {eq: "fr-FR"}}) {
      nodes {
        ...zone
      }
    }
    zonesEN: allSanityZone(filter: {locale: {eq: "en-GB"}}) {
      nodes {
        ...zone
      }
    }
    zonesIT: allSanityZone(filter: {locale: {eq: "it-IT"}}) {
      nodes {
        ...zone
      }
    }
    menuHeaderFR: sanityMenu(location: {in: "header"}, locale: {eq: "fr-FR"}) {
      ...menu
    }
    menuHeaderEN:sanityMenu(location: {in: "header"}, locale: {eq: "en-GB"}) {
      ...menu
    }
    menuHeaderIT:sanityMenu(location: {in: "header"}, locale: {eq: "it-IT"}) {
      ...menu
    }
    menuFooterFR:sanityMenu(location: {in: "footer"}, locale: {eq: "fr-FR"}) {
      ...menu
    }
    menuFooterEN:sanityMenu(location: {in: "footer"}, locale: {eq: "en-GB"}) {
      ...menu
    }
    menuFooterIT:sanityMenu(location: {in: "footer"}, locale: {eq: "it-IT"}) {
      ...menu
    }
  }
`

const Layout = ({children, pageContext: {locale, template}}) => {
  const {
    sanitySettings,
    zonesFR,
    zonesEN, 
    zonesIT, 
    menuHeaderFR,
    menuHeaderEN,
    menuHeaderIT,
    menuFooterFR,
    menuFooterEN,
    menuFooterIT
  } = useStaticQuery(query)
  const settings = sanitySettings

  const _LocaleContext = useContext(LocaleContext)
  const {localeCtx, dispatch} = _LocaleContext
  
  const [layoutLocale, setLayoutLocale] = useState(locale)
  // console.log(_LocaleContext)
  // console.log(locale, localeCtx)
  const page = useRef()

  useEffect(() => {
    window.addEventListener('scroll', _onScroll)
    _onScroll()

    return () => window.removeEventListener('scroll', _onScroll)
  }, [])

  const _onScroll = (e) => {
    if (window.innerWidth > 768) return
    if (template !== 'home') return

    if (window.pageYOffset < (window.innerHeight - 75)) {
      page.current.classList.add('has-header-sticky')
    } else {
      page.current.classList.remove('has-header-sticky')
    }
  }

  useEffect(() => {
    dispatch(locale)
  }, [locale])

  useEffect(() => {
    setLayoutLocale(localeCtx)
  }, [localeCtx])
// console.log("layoutLocale",layoutLocale)
  let zones, menuHeader, menuFooter, video
  if(layoutLocale === "fr-FR"){
    zones = zonesFR
    menuHeader = menuHeaderFR
    menuFooter = menuFooterFR
    video = sanitySettings.videoMp4FR.asset.url
  }
  if(layoutLocale === "en-GB"){
    zones = zonesEN
    menuHeader = menuHeaderEN
    menuFooter = menuFooterEN
    video = sanitySettings.videoMp4EN.asset.url
  }
  if(layoutLocale === "it-IT"){
    zones = zonesIT
    menuHeader = menuHeaderIT
    menuFooter = menuFooterIT
    video = sanitySettings.videoMp4IT ? sanitySettings.videoMp4IT.asset.url : sanitySettings.videoMp4FR.asset.url
  }
  // const zones = layoutLocale === "fr-FR"
  //  ? zonesFR
  //  : zonesEN
  
  // const menuHeader = layoutLocale === "fr-FR"
  //  ? menuHeaderFR
  //  : menuHeaderEN

  // const menuFooter = layoutLocale === "fr-FR"
  //  ? menuFooterFR
  //  : menuFooterEN

  // const video = layoutLocale === "fr-FR"
  //  ? sanitySettings.videoMp4FR.asset.url
  //  : sanitySettings.videoMp4EN.asset.url

  const isHome = template === "home"
  const isZones = template === "zones" || template === "zone" || template === "home"

  return (
 
    <WrapperContext.Provider value={{
      locale,
      settings,
      zones,
      video
    }}>
      <div id="page" ref={page}>

        {isHome && <Video input={video} />}
        {isZones && <ZonesMap zones={zones}/>}
        
        <Header menu={menuHeader}/>
        <main>
          {children}
        </main>
        <Footer 
          template={template} 
          menu={menuFooter}/>
      </div>
    </WrapperContext.Provider>

  )
}

export { WrapperContext, Layout }

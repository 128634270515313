import React from 'react';
import { Link } from 'gatsby'
import { _getLocalizedText } from '../lib/helpers'

const Cta = ({label, url, icon, isLg=false}) => {
  // console.log(isLg)
  return (
    <Link to={url} >
      <div className={`cta btn btn-primary ${isLg ? 'btn-lg' : ''}`}>
        {icon === "arrow-w" && <span className={`icon icon-${icon}`}></span>}
        <span>{_getLocalizedText(label)}</span>
        {icon === "arrow-e" && <span className={`icon icon-${icon}`}></span>}
      </div>
    </Link>
  );
};

export default Cta;
import React, {useContext, useEffect, useState} from 'react'
import {Link} from 'gatsby'
import PubSub from 'pubsub-js'
import {WrapperContext} from './Layout'
import LocaleSwitcher from './ui/LocaleSwitcher'
import Burger from './ui/Burger'
import {_getHomePath, _localizedSlug, _getLocalizedText, _getZonesPath} from '../lib/helpers'

const logo = require('../images/tara-logo.svg');
const logoBlanc = require('../images/tara-logo-blanc.svg');
const logoEn = require('../images/tara-logo-en.svg');
const logoBlancEn = require('../images/tara-logo-blanc-en.svg');

// const Logo = ({locale}) => (
//   <>
//   {locale === 'fr-FR' 
//   ? 
//   }
//   </>
// )

const Header = ({menu}) => {
  // const { sanitySettings } = useStaticQuery(query)
  const _WrapperContext = useContext(WrapperContext)
  const { settings, locale } = _WrapperContext
  const [navClass, setNavClass] = useState('')

  console.log(_WrapperContext)

  useEffect(() => {
    const tokenR = PubSub.subscribe('ROUTE_UPDATE', (e, d) => {
      PubSub.publish('BURGER.CLOSE')
    })

    const tokenB = PubSub.subscribe('BURGER', (e, d) => {
      const className = d
        ? 'is-active'
        : ''
      setNavClass(className)
    })

    return () => {
      PubSub.unsubscribe(tokenR)
      PubSub.unsubscribe(tokenB)
    }
  }, [])

  return (
    <header className={navClass}>
      <div className={`container`}>
        <div className='x xjb xac'>

          <div className='site-name'>
            <Link to={_getHomePath()} onClick={() => PubSub.publish('BURGER.CLOSE')}>
              {locale === 'en-GB' 
              ? <>
                  <img src={logoEn} alt={settings.title} className='logo' />
                  <img src={logoBlancEn} alt={settings.title} className='logo-blanc xs-only' />
                </>
              : <>
                <img src={logo} alt={settings.title} className='logo' />
                <img src={logoBlanc} alt={settings.title} className='logo-blanc xs-only' />
                </>
              }
              
            </Link>
          </div>

          <nav className={`fSeg strong ${navClass}`}>
            <ul className="x main-nav">
              <li className="xs-only ttu">
                <Link to={_getHomePath()}>{_getLocalizedText("home")}</Link>
              </li>
              <li className="xs-only ttu">
                <Link to={_getZonesPath()}>{_getLocalizedText("visit")}</Link>
              </li>
              {menu.items.map((item,i) => (
                <li key={i} className=" ttu">
                  <Link to={_localizedSlug(item.link)}>{item.title}</Link>
                </li>
              ))}
              
              
            </ul>
            <LocaleSwitcher />
            <div className="ico-close curp xs-only" onClick={() => PubSub.publish("BURGER.CLOSE")}>
              <i className="icon icon-close"></i>
            </div>
          </nav>
          <Burger />
        </div>
        
        
      </div>
    </header>
      
  );

}

export default Header;
import React from 'react';
import ReactCookieConsent from 'react-cookie-consent';
import { _getLocalizedText } from '../../lib/helpers'

const CookieConsent = () => {
  return (
    <ReactCookieConsent
      containerClasses="cookie-consent"
      contentClasses="cookie-consent--content"
      disableStyles={true}
      location="bottom"
      buttonText={_getLocalizedText("cookieButtonText")}
      declineButtonText={_getLocalizedText("cooieDeclineButtonText")}
      cookieName="gatsby-gdpr-google-analytics"
      acceptOnScroll={false}>
        <div dangerouslySetInnerHTML={{
          __html: _getLocalizedText("cookieMessage")
        }} />
      </ReactCookieConsent>
  );
};

export default CookieConsent;
import React, { useEffect, useRef, useState, useContext } from 'react'
// import { Link } from 'gatsby';
import { useStaticQuery, graphql } from 'gatsby'
// import Img from "gatsby-image"
import PubSub from 'pubsub-js'
import panzoom from 'panzoom'
import { WrapperContext } from './Layout'
import ZonePoi from './ZonePoi'
import Cta from '../components/Cta'
import { _localizedSlug, _getLocalizedText } from '../lib/helpers'

// const visuel = require("../images/tara-polar-station-zones-q75.jpg")
let isTouch, panZoom
const ZonesMap = () => {
  // console.log("ZonesMap")
  // console.log(props)
  const { file } = useStaticQuery(graphql`
    query ImageQ {
      # visuel: imageSharp(id: { regex: "/tara-polar-station-zones-q75.jpg/" }) {
      #   sizes(quality: 100) {
      #     ...GatsbyImageSharpSizes_withWebp
      #   }
      # }
      file(relativePath: {eq: "tara-polar-station-zones-v4.jpg"}) {
        childImageSharp {
          # fluid(quality: 100) {
          #   ...GatsbyImageSharpFluid
          # }
          fixed(width: 3000, height: 3146) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)
  // console.log(file)
  // console.log(file)
  const _WrapperContext = useContext(WrapperContext)
  const { zones } = _WrapperContext

  const [loaded, setLoaded] = useState(false)
  const [coupe, setCoupe] = useState()
  const [rand, setRand] = useState('134679258')
  const [currentZone, setCurrentZone] = useState({})

  const [bounding, setBounding] = useState({
    scale: 1,
    defaultPositionX: 0,
    defaultPositionY: 0
  })
  // const [transform, setTransform] = useState()
  const wrapper = useRef()
  const visuelRef = useRef()
  const overlayRef = useRef()
  const coupeRef = useRef()

  let destW, destH
  let defaultPositionX, defaultPositionY

  useEffect(() => {
    isTouch = window.innerWidth <= 1023 && 'ontouchstart' in window
    // console.log("isTouch",isTouch)
    setRand(new Date().getTime())

    setTimeout(() => {
      setLoaded(true)
    }, 1000)
    window.addEventListener('resize', _format)

    const tokenZone = PubSub.subscribe('ZONE_CHANGE', (e, d) => {
      // console.log(d)
      setCurrentZone(d)
    })

    const tokenRoute = PubSub.subscribe('ROUTE_UPDATE', (e, d) => {
      _reset()
    })

    const tokenCoupe = PubSub.subscribe('COUPE', (e, d) => {
      setCoupe(d)
    })

    const tokenZoneStatus = PubSub.subscribe('ZONE_STATUS', (e, d) => {
      // console.log(d)
      if (d !== 'initial') {
        coupeRef.current.style.display = 'none'
      } else {
        coupeRef.current.style.display = 'block'
      }
    })

    return () => {
      window.removeEventListener('resize', _format)
      PubSub.unsubscribe(tokenZone)
      PubSub.unsubscribe(tokenRoute)
      PubSub.unsubscribe(tokenCoupe)
      PubSub.unsubscribe(tokenZoneStatus)
    }
  }, [])

  const _reset = () => {
    // const {
    //   // defaultPositionX,
    //   defaultPositionY
    // } = bounding
    setCoupe(null)
    // console.log(defaultPositionY)
    if (panZoom) {
      panZoom.moveTo(defaultPositionX, defaultPositionY)
      panZoom.smoothZoom(0, 0, 0)
    }
  }

  const _onLoad = () => {
    // console.log(visuelRef.current)
    setLoaded(true)
    _format()
    // console.log("isTouch",isTouch)
    if (isTouch) {
      _adjustXMobile()
      return
    }

    if (window.innerWidth >= 1024) {
      panZoom = panzoom(wrapper.current, {
        maxZoom: 1,
        minZoom: 1,
        bounds: true,
        boundsPadding: 1,
        transformOrigin: { x: 0.5, y: 0.5 }
      })

      _format()
    }
  }

  const _format = (e) => {
    // console.log(screen.availHeight, screen.height, document.documentElement.clientHeight)
    const ww = window.innerWidth * 1.2
    const wh = isTouch
      ? document.documentElement.clientHeight * 1.2
      : window.innerHeight * 1.2

    const w = 3000
    const h = 3146
    const windowRatio = ww / wh
    const imageRatio = w / h

    if (windowRatio > imageRatio) {
      destW = ww
      destH = ww / imageRatio
    } else {
      destH = wh
      destW = wh * imageRatio
    }

    const scaleFactor = destW / w
    defaultPositionX = window.innerWidth / 2 - destW / 2 * 1.2
    defaultPositionY = window.innerHeight / 2 - destH / 2
    // const defaultPositionY = 0
    // console.log(ww, destW, panZoom)

    wrapper.current.style.width = destW + 'px'
    wrapper.current.style.height = destH + 'px'

    if (panZoom) {
      // const x = window.innerWidth/2 - w/2
      // console.log("moveTo", defaultPositionX, defaultPositionY)
      panZoom.moveTo(defaultPositionX, defaultPositionY)
      // panZoom.smoothZoom(-x, 0, 1.5);
      // panZoom.moveBy(defaultPositionX, defaultPositionY)
    }
    // console.log(defaultPositionY)
    setBounding(bounding => ({
      ...bounding,
      scale: scaleFactor,
      defaultPositionX: defaultPositionX,
      defaultPositionY: defaultPositionY
    }))
  }

  const _adjustXMobile = () => {
    const x = (window.innerWidth / 2 - destW / 2) * 1.6
    // console.log(x)
    // wrapper.current.style.width = "100%!important"
    wrapper.current.style.transform = `translateX(${x}px)`
    // window.removeEventListener("resize", _format)
  }

  // console.log(loaded)
  //error if called in render
  const ctaLabel = _getLocalizedText('visitZone')

  return (
    <div className={`zone-map ${(!loaded ? 'loading' : '')}`}>
      <div className='inner' ref={wrapper}>
        <img
          src={`${file.childImageSharp.fixed.src}?_=${rand}`}
          srcSet={file.childImageSharp.fixed.srcSet}
          alt='Visuel zones'
          ref={visuelRef}
          loading='eager'
          // importance="high"
          onLoad={_onLoad} />

        <div className='zone-layer' ref={overlayRef}>
          {zones.nodes.map((item, i) => <ZonePoi key={i} index={i} item={item} />
          )}
        </div>

        <div className='coupe' ref={coupeRef}>
          {coupe && <img src={coupe} alt='visuel coupe' />}
        </div>
      </div>

      {currentZone && currentZone.title &&
        <>
          <div className='zone-header xs-only headline ttu'>{currentZone.title}</div>
          <div className='zone-cta xs-only'>
            <Cta label={ctaLabel} url={_localizedSlug(currentZone)} icon='arrow-e' isLg />
          </div>
        </>
      }

    </div>
  )
}

export default ZonesMap

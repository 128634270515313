// const website = require('./website')

module.exports = {
  "fr-FR": {
    default: true,
    label: "Fr",
    path: "fr",
    locale: "fr-FR",
    siteLanguage: "fr",
    ogLang: "fr_FR",
    loginMessage:
      "L'accès au site est restreint. Veuillez entrer le mot de passe pour accéder au contenu exclusif.",
    pass: "Mot de passe",
    retour: "Retour",
    visiter: "Visiter",
    vueEnsemble: "Vue d'ensemble",
    financer: "Parrainer la zone",
    information: "Information",
    contact: "Contact",
    confirm: "Confirmer",
    informationMessage:
      "Je souhaite recevoir un certificat de participation à la construction de la Tara International Polar Station.",
    messageOptin:
      "J'accepte que la Fondation Tara Océan conserve ces données pour une durée de 5 ans à compter de la fin du parrainage. Je reçois mon certification de participation par email.",
    success: "MERCI DE VOTRE PARTICIPATION",
    successMessage:
      "Vous trouverez votre certificat de participation dans votre boite email.",
    last_name: "Nom",
    first_name: "Prénom",
    email: "Email",
    contribution: "Montant investissement client",
    code_banquier: "Code banquier",
    cookieButtonText: "Accepter",
    cookieDeclineButtonText: "Refuser",
    cookieMessage:
      "La Fondation Tara Ocean utilise des cookies à des fins statistiques. Ces cookies sont désactivés à moins que vous ne choisissiez de les accepter. Vous pouvez obtenir plus d’informations sur les cookies utilisés par la Fondation Tara Océan et modifier vos préférences à tout moment en consultant notre Politique de protection des données. <a href='/mentions-legales'>En savoir plus</a>, <a href='/politique-de-protection-des-donnees'>Politique de protection des données</a>",
    loginMessage:
      "L'accès au site est restreint. Veuillez entrer le mot de passe pour accéder au contenu exclusif.",
    password: "Mot de passe",
    discoverTips: "Découvrir la TIPS",
    home: "Accueil",
    visit: "La visite",
    visitZone: "VISITER CETTE ZONE",
  },
  "it-IT": {
    default: false,
    label: "It",
    path: "it",
    locale: "it-IT",
    siteLanguage: "it",
    ogLang: "it_IT",
    loginMessage:
      "L’accesso al sito è soggetto a restrizioni. Si prega di inserire la password per accedere",
    pass: "Password",
    retour: "ritornare",
    visiter: "Esplora",
    vueEnsemble: "Quadro generale",
    financer: "Sponsorizza la zona",
    information: "informazione",
    contact: "Contact",
    confirm: "Confermare",
    informationMessage:
      "Vorrei ricevere un attestato di partecipazione alla costruzione della Stazione Polare Internazionale di Tara",
    messageOptin:
      "Accetto che la Fondazione Tara Océan conserverà questi dati per un periodo di 5 anni dalla fine della sponsorizzazione. Ricevo la mia certificazione di partecipazione tramite e-mail.",
    success: "Grazie per la vostra partecipazione",
    successMessage:
      "Troverete il vostro certificato di partecipazione nella vostra casella di posta elettronica.",
    last_name: "Nome",
    first_name: "Cognome",
    email: "E-mail",
    contribution: "Importo dell'investimento del cliente",
    code_banquier: "Codice banca",
    cookieButtonText: "Accetta",
    cookieDeclineButtonText: "Rifiuti",
    cookieMessage:
      "La Fondazione TARA Ocean usa i cookies per fini statistici. I cookies saranno disabilitati a meno che non vengano accettati. Puoi avere maggiori informazioni sui cookies usati da TARA o cambiare le preferenze in qualsiasi momento attraverso le nostre poitiche di Privacy. <a href='/it/note-legali'>Leggi di più</a>, <a href='/it/politica-sulla-riservatezza'>Privacy Policy</a>",
    loginMessage:
      "L’accesso al sito è soggetto a restrizioni. Si prega di inserire la password per accedere",
    password: "Password",
    discoverTips: "Scopri TIPS",
    home: "Benvenuto",
    visit: "Il tour",
    visitZone: "Esplora la zona",
  },
  "en-GB": {
    default: false,
    label: "En",
    path: "en",
    locale: "en-GB",
    siteLanguage: "en",
    ogLang: "en_GB",
    loginMessage:
      "Access to the site is restricted. Please enter the password to access exclusive content.",
    pass: "Password",
    retour: "Back",
    visiter: "Visit",
    vueEnsemble: "Overview",
    financer: "Sponsor the area",
    information: "Information",
    contact: "Contact",
    confirm: "Confirm",
    informationMessage:
      "I would like to receive a certificate of participation in the construction of the Tara International Polar Station.",
    messageOptin:
      "I accept that the Tara Ocean Foundation will keep this data for 5 years from the end of the sponsorship. I'll receive my sponsorship certification by email.",
    success: "THANK YOU FOR YOUR PARTICIPATION",
    successMessage:
      "You will find your certificate of participation in your mailbox.",
    last_name: "Lastname",
    first_name: "Firstname",
    email: "Email",
    contribution: "Client investment amount",
    code_banquier: "Banker's code",
    cookieButtonText: "Accept",
    cookieDeclineButtonText: "Decline",
    cookieMessage:
      "The Tara Ocean Foundation uses cookies for statistics purposes. These cookies are disabled unless you choose to accept them. You can get more information and the cookies used by the Tara Ocean Foundation and change your preferences at any time through our Privacy Policy. <a href='/en/legales-notices'>read more</a>, <a href='/en/privacy-policy'>Privacy policy</a>",
    loginMessage:
      "Access to the site is restricted. Please enter the password to access exclusive content.",
    password: "Password",
    discoverTips: "Discover the TIPS",
    home: "Home",
    visit: "The visit",
    visitZone: "VISIT THIS ZONE",
  },
}

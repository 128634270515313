import React, { useContext } from "react"
import { WrapperContext } from "../components/Layout"
import { LocaleContext } from "../components/ui/LocaleWrapper"

// import {format, isFuture} from 'date-fns'
const locales = require("../../config/i18n")

export const _getHomePath = () => {
  // const _WrapperContext = useContext(WrapperContext)
  // const { locale } = _WrapperContext
  const _LocaleContext = useContext(LocaleContext)
  const { localeCtx } = _LocaleContext
  console.log(localeCtx)
  switch (localeCtx) {
    case "fr-FR":
      return "/"
    case "en-GB":
      return "/en"
    case "it-IT":
      return "/it"
    default:
      return "/"
  }
  // if(locale === "fr-FR")return "/"
  // else if(locale === "en-GB")return "/en"
  // else return "/"
}

export const _getZonesPath = () => {
  // const _WrapperContext = useContext(WrapperContext)
  // const { locale } = _WrapperContext
  const _LocaleContext = useContext(LocaleContext)
  const { localeCtx } = _LocaleContext

  switch (localeCtx) {
    case "fr-FR":
      return "/zones"
    case "en-GB":
      return "/en/zones-en"
    case "it-IT":
      return "/it/zones-it"
    default:
      return "/zones"
  }
}

export const _getLocalizedText = text => {
  // console.log(LocaleContext)
  // const locale = _WrapperContext ? _WrapperContext.locale : 'fr-FR'
  // const locale = "en"
  const _LocaleContext = useContext(LocaleContext)
  const { localeCtx } = _LocaleContext

  // console.log("localeCtx",localeCtx, text)
  return locales[localeCtx] && locales[localeCtx][text]
    ? locales[localeCtx][text]
    : text
}

// export const _getLocalizedTextByLocale = (text, locale) => {
//   return locales[locale] && locales[locale][text]
//   ? locales[locale][text]
//   : text
// }

export const _localizedSlug = node => {
  // console.log(node)
  // if(!node.uid)return '/'
  const link = linkResolver(node)
  // console.log(locales)
  return locales[node.locale].default
    ? `/${link}`
    : `/${locales[node.locale].path}/${link}`
}

// export const _localizedSlugWithPrefix = (node) => {

// }

export const linkResolver = doc => {
  // URL for a category type
  // console.log(doc.type, doc.uid)
  // if(doc.type === "page")return `/${doc.uid}`;
  if (doc) {
    switch (doc._type) {
      case "zone":
        return `zone/${doc.slug.current}`
      // case 'page':
      //   return `/blog/${doc.uid}`;
      // case 'page':
      //   return `/${doc.uid}`;
      default:
        if (doc._type) {
          return `${doc.slug.current}`
        }
        return `${doc.type}`
    }
  }

  return "/"
}

export function cn(...args) {
  return args.filter(Boolean).join(" ")
}

export function mapEdgesToNodes(data) {
  if (!data.edges) return []
  return data.edges.map(edge => edge.node)
}

export function filterOutDocsWithoutSlugs({ slug }) {
  return (slug || {}).current
}

// export function filterOutDocsPublishedInTheFuture ({publishedAt}) {
//   return !isFuture(publishedAt)
// }

// export function getBlogUrl (publishedAt, slug) {
//   return `/blog/${format(publishedAt, 'YYYY/MM')}/${slug.current || slug}/`
// }

export function buildImageObj(source = { asset: {} }) {
  const imageObj = {
    asset: { _ref: source.asset._ref || source.asset._id },
  }

  if (source.crop) imageObj.crop = source.crop
  if (source.hotspot) imageObj.hotspot = source.hotspot

  return imageObj
}

export function toPlainText(blocks) {
  if (!blocks) {
    return ""
  }
  return blocks
    .map(block => {
      if (block._type !== "block" || !block.children) {
        return ""
      }
      return block.children.map(child => child.text).join("")
    })
    .join("\n\n")
}

export function uuid() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function(c) {
    var r = (Math.random() * 16) | 0
    var v = c == "x" ? r : (r & 0x3) | 0x8
    return v.toString(16)
  })
}

export function randomSlug(length = 10) {
  const now = Date.now()
  // console.log(start)
  let slug = ""
  let chars = "abcdefghijklmnopqrstuvwxyz0123456789"

  for (let i = 0; i < length; i++) {
    slug += chars.charAt(Math.floor(Math.random() * chars.length))
  }
  console.log(slug)
  return `${slug}-${now}`
}

export function slugify(input) {
  return input
    .toString()
    .toLowerCase()
    .replace(/\s+/g, "-") // Replace spaces with -
    .replace(/[^\w\-]+/g, "") // Remove all non-word chars
    .replace(/\-\-+/g, "-") // Replace multiple - with single -
    .replace(/^-+/, "") // Trim - from start of text
    .replace(/-+$/, "") // Trim - from end of text
}

// export function fileNameByUrl(url) {
// 	const decoded = decodeURIComponent(url)
// 	return decoded.substring(decoded.lastIndexOf("/") + 1)
// }

export function JSON2CSV(objArray) {
  var array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
  var str = '';

  for (var i = 0; i < array.length; i++) {
      var line = '';
      for (var index in array[i]) {
          if (line != '') line += ','

          line += array[i][index];
      }

      str += line + '\r\n';
  }

  return str;
}
import React, { useContext } from "react"
import { Link } from "gatsby"
// import PubSub from "pubsub-js"

import { LocaleContext } from "./LocaleWrapper"
const locales = require("../../../config/i18n")

// class LangSwitcher extends Component {
// 	constructor(props) {
// 		super(props)
// 		// const { i18n } = this.props
// 		this.state = {
// 			//language: i18n.language,
// 			nav: [],
// 			path: "",
// 		}
// 		//console.log(locales)
// 		this.handleChangeLanguage = this.handleChangeLanguage.bind(this)
// 	}

// 	componentDidMount() {
// 		Object.values(locales).forEach(locale => {
// 			//console.log(locale)
// 			// const url = this._getLocalizedUrl(locale)
// 			const url = locale.default
// 			? "/"
// 			: locale.path
// 			///console.log(url)
// 			const nav = this.state.nav
// 			const li = {
// 				url: url,
// 				label: locale.label,
// 				locale: locale.locale,
// 			}
// 			nav.push(li)
// 			this.setState({
// 				nav: nav,
// 			})
// 		})

// 		//this._browserLang()
// 	}

// 	// _getLocalizedUrl(locale) {
// 	// 	let { pathname } = window.location
// 	// 	let url = ""
// 	// 	if (pathname.indexOf("zone") > -1) {
// 	// 		const rm = "/" + locale.path + "/"
// 	// 		pathname = pathname.replace(rm, "/")
// 	// 		//console.log(locale)
// 	// 		url = locale.default // === "en"
// 	// 			? pathname
// 	// 			: locale.path + pathname
// 	// 	} else {
// 	// 		url = locale.default ? "/" : "/" + locale.path
// 	// 	}
// 	// 	return url
// 	// }

// 	//   _browserLang(){
// 	//     const _userLang = cookie.load('_userLang')
// 	//     if(!_userLang){
// 	//       if(navigator){
// 	//         const userLang = navigator.language || navigator.userLanguage;
// 	//         cookie.save('_userLang', userLang, { path: '/' })
// 	//         if(userLang !== this.props.lang){
// 	//           //redirect
// 	//           const locale = locales[userLang.toLowerCase()]
// 	//           const url = this._getLocalizedUrl(locale)
// 	//           navigate(url)
// 	//         }
// 	//       }

// 	//     }
// 	//   }

// 	handleChangeLanguage(lng) {
// 		// const { i18n } = this.props
// 		// i18n.changeLanguage(lng)
// 	}



// import { WrapperContext } from '../Layout';

const LocaleSwitcher = () => {
	const _LocaleContext = useContext(LocaleContext)
	const { localeCtx, dispatch } = _LocaleContext 
	// console.log(_LocaleContext)

	// const _WrapperContext = useContext(WrapperContext)
	// const { locale } = _WrapperContext || "fr-FR"
	
	const renderLanguages = () => {
		// const { nav } = this.state
    // const { locale } = this.props
    // locales.splice(2, 1)
    // console.log(locales)
		const languages = Object.values(locales).map((li, key) => {
			const _className = li.locale === localeCtx ? "active" : ""
      const path = li.default ? `/` : `/${li.path}`

      //Hide IT from menu
      // if(li.path === 'it')return null
			return (
				<li key={key} className={_className}>
					<Link to={path} className={_className + " ttu"} onClick={() => dispatch(li.locale)}>
						{li.label}
					</Link>
				</li>
			)
		})
		return languages
	}

	return(
		<ul className="language-switcher x">
			{renderLanguages()}
		</ul>
	)
}

export default LocaleSwitcher

import React, {useEffect, useState} from 'react'
import {Link, navigate} from 'gatsby'
import PubSub from 'pubsub-js'
import {_localizedSlug} from '../lib/helpers'

const ZonePoi = ({index, item}) => {
  const [active, setActive] = useState()

  useEffect(() => {
    const token = PubSub.subscribe('ZONE_CHANGE', (e, d) => {
      setActive(d._id === item._id)
    })

    return () => {
      PubSub.unsubscribe(token)
    }
  }, [])

  const positions = item.positions.split(',')
  const isLeft = positions[0] < 67
  const positionClass = isLeft
    ? 'is-left'
    : 'is-right'

  const activeClass = active
    ? 'is-active'
    : ''

  const style = {
    left: positions[0] + '%',
    top: positions[1] + '%'
  }

  const _onClick = (url) => {
    if (window.innerWidth < 768) {
      // setActive(true)
      PubSub.publish('ZONE_CHANGE', item)
    } else {
      navigate(url)
    }
  }
  // console.log("activeClass",activeClass)
  return (
    <div className={`poi poi-${index} ${activeClass} ${positionClass}`} style={style}>
      <div onClick={() => _onClick(_localizedSlug(item))} >
        <div className='cursor' />
        <div className='header x xac fSeg ttu '>
          <div className='title fM'>{item.title}</div>
          <div className='stripe' />
        </div>
      </div>
    </div>
  )
}

export default ZonePoi

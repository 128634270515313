import React, { useState, useEffect, useRef } from 'react'
import ReactPlayer from 'react-player'
import screenfull from 'screenfull'
import { _getLocalizedText } from '../../lib/helpers'

const Controls = ({ input }) => {
  const {
    status,
    setStatus,
    player
  } = input

  if (screenfull.isEnabled) {
    useEffect(() => {
      screenfull.on('change', _onFullScreenChange)

      return () => screenfull.off('change', _onFullScreenChange)
    }, [])

    useEffect(() => {
      // console.log('screenfull')
      // console.log(screenfull.isEnabled)
      if (status && screenfull) {
        if (status.fullscreen) {
          screenfull.request(player.current)
        } else {
          screenfull.exit()
        }
      }
    }, [status.fullscreen])
  }

  const _onPlayPauseClick = () => {
    setStatus({
      ...status,
      playing: !status.playing
    })
  }

  const _onMuteClick = () => {
    setStatus({
      ...status,
      mute: !status.mute
    })
  }

  const _onFullScreenClick = () => {
    setStatus({
      ...status,
      fullscreen: !status.fullscreen
    })
  }

  const _onFullScreenChange = () => {
    if (!screenfull.isFullscreen) {
      setStatus({
        ...status,
        fullscreen: false
      })
    }
  }

  // console.log(status)
  return (
    <ul className='controls'>
      <li className='playing ' onClick={_onPlayPauseClick}>
        <span className={`curp ${status.playing ? 'icon-pause' : 'icon-play'}`}></span>
      </li>
      <li className='mute ' onClick={_onMuteClick}>
        <span className={`curp ${status.mute ? 'icon-mute-on' : 'icon-mute-off'}`}></span>
      </li>
      {screenfull.isEnabled &&
        <li className='fullscreen ' onClick={_onFullScreenClick}>
          <span className={`curp ${status.fullscreen ? 'icon-fullscreen-off' : 'icon-fullscreen-on'}`}></span>
        </li>
      }
    </ul>
  )
}

const VideoWrapper = ({ input }) => {
  // console.log(input)
  const [ready, setReady] = useState(false)
  const [status, setStatus] = useState({
    mute: true,
    playing: true,
    fullscreen: false
  })

  const player = useRef()

  useEffect(() => {
    document.addEventListener("scroll", _onScroll)

    return () => {
      document.removeEventListener("scroll", _onScroll)

    }
  }, [])


  const _onFullScreenChange = (e) => {
    console.log(e)
  }

  const _onScroll = () => {
    // console.log(window.pageYOffset)
    if (window.pageYOffset > 0) {
      setStatus({
        ...status,
        playing: false
      })
    } else {
      setStatus({
        ...status,
        playing: true
      })
    }
  }

  const _onReady = () => setReady(true)

  const _onEnded = () => {
    const footer = document.querySelector("footer")
    footer.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
  }

  const config = {
    youtube: {
      playerVars: {
        controls: 0,
        disablekb: 1,
        enablejsapi: 1,
        iv_load_policy: 3,
        modestbranding: 1,
        showinfo: 0,
        cc_load_policy: 0,
        modestbranding: 1,
        showinfo: 0,
        rel: 0,
        origin: 'https://tara-polar.netlify.app/'
      }
    },
    vimeo: {
      title: false,
      background: true,
      controls: false
    }
  }

  return (
    <div className="video-wrapper" ref={player}>
      <ReactPlayer
        className='player'

        url={input}
        // url="https://vimeo.com/78429727"
        playing={status.playing}
        // loop={true}
        muted={status.mute}
        playsinline
        config={config}
        width="100%"
        height="100%"
        onReady={_onReady}
        onEnded={_onEnded}
      />
      <div className="footer container">
        <div className="inner">
          <div className="cta-scroll">
            <div>{_getLocalizedText("discoverTips")}</div>
            <i className="icon-chevron-s-alt"></i>
          </div>
          {ready && <Controls input={{ status, setStatus, player }} />}
        </div>
      </div>


    </div>
  );
};

export default VideoWrapper;
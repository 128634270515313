import React, { useContext, useState } from 'react';
import {useStaticQuery, graphql} from 'gatsby'
import { OauthContext } from './index'
import { _getLocalizedText } from '../../lib/helpers'
const logo = require("../../images/tara-logo.svg")

const query = graphql `
  query {
    sanitySettings {
      codeSite
    }
  }
`

const Login = () => {
  const _OauthContext = useContext(OauthContext)
  const { isOauth, dispatch } = _OauthContext

  const { sanitySettings } = useStaticQuery(query)

  const [state, setState] = useState()
  // console.log("Login isOauth", isOauth, dispatch, _OauthContext)

  const _onSubmit = (e) => {
    // console.log(e)
    e.preventDefault();
    if(sanitySettings.codeSite === state){
      dispatch({ type: "LOGIN", payload: true })
    }else{
      alert("Code invalide")
    }
  }

  return (
    <div className="oauth login modal">
      <div className="site-name">
        <img src={logo} alt={"logo"} height="59"/>
      </div>
      <div className="inner">
        <p>{_getLocalizedText("loginMessage")}</p>
        <form action="" onSubmit={_onSubmit}>
          <div className="form-row x xjb">
            <input 
              type="password" 
              name="passwd" 
              placeholder={_getLocalizedText("password")}
              onChange={(e) => setState(e.target.value)}
              />
            <button type="submit">
              <span className="icon icon-arrow-e"></span>
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
import React from 'react';
import { Link } from 'gatsby'
import Social from './ui/Social'
import Partenaires from './Partenaires'
import { _localizedSlug } from '../lib/helpers'

const odd = require("../images/odd.jpg")

const Footer = ({ template, menu }) => {
    // console.log(template)
  return(
    <footer className={`footer-${template} fSeg strong`}>
      <div className="container">
          
          {template === 'home' &&
          <div className="x xjb">
              <div className="w50">
                  <Partenaires />
              </div>
              <div className="text-right x">
                  <div className="logos">
                      <img src={odd} alt="logo odd" height="49" width="" />
                  </div>
                  <div className="vertical-wrapper">
                      <div className="vertical">
                      <nav>
                          <ul className=" ">
                          {menu.items.map((item,i) => (
                              <li key={i}>
                              <Link to={_localizedSlug(item.link)}>{item.title}</Link>
                              </li>
                          ))}
                          </ul>
                      </nav>
                      <div>Crédit Olivier Petit Architecte</div>
                      </div>
                  </div>
                  
              </div>
          </div>
          }

          {template !== 'home' &&
          <div className="x xje">
              <div className=" text-right xdc xje">
                  
                  <nav>
                      <ul className="">
                      {menu.items.map((item,i) => (
                          <li key={i}>
                          <Link to={_localizedSlug(item.link)}>{item.title}</Link>
                          </li>
                      ))}
                      </ul>
                  </nav>
                  <div>Crédit Olivier Petit Architecte</div>
                  
              </div>
          </div>
          }

  
      </div>
    </footer>
      
  )
}

export default Footer;